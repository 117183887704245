import JSEncrypt from 'jsencrypt';
import { AuthConstants } from '../_constants/auth-constants';



// make string as encrypted
export function encrytString(str: string): string {
    const RSAEncrypt = new JSEncrypt({});
    RSAEncrypt.setPublicKey(AuthConstants.PUBLIC_KEY);
    return (RSAEncrypt.encrypt(str)).toString();
}

// make string as decrypted
export function decryptString(str: string): string {
    const RSAEncrypt = new JSEncrypt({});
    RSAEncrypt.setPrivateKey(AuthConstants.PRIVATE_KEY);
    return (RSAEncrypt.decrypt(str)).toString();
}
