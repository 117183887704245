import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/shared/_services/utility.service';

@Component({
    selector: 'app-layout-app',
    templateUrl: './layout-app.component.html',
    styleUrls: ['./layout-app.component.scss']
})
export class LayoutAppComponent implements OnInit {

    sidebarSizeSm: boolean;

    constructor(
        private utilityService: UtilityService
    ) {

    }

    ngOnInit(): void {
        this.utilityService.isSidebarSizeSmall().subscribe(state => {
            this.sidebarSizeSm = state;
        });
    }

}
