import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private count = 0;
  private spinnerSubject = new BehaviorSubject<string>('');

  constructor() { }

  getSpinnerObserver(): Observable<string> {
    return this.spinnerSubject.asObservable();
  }

  spinnerStarted() {
    if (++this.count === 1) {
      this.spinnerSubject.next('start');
    }
  }

  spinnerEnded() {
    if (this.count === 0 || --this.count === 0) {
      this.spinnerSubject.next('stop');
    }
  }

  resetSpinner() {
    this.count = 0;
    this.spinnerSubject.next('stop');
  }

}
