import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GlobalConstants } from 'src/app/shared/_constants/global-constants';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {
  public changeSubject = new Subject<{ key: string; value: any }>();
  public projectOverviewLoadFlag = true;
  public standardId = 0;
  public projectSelfAssessment = {};

  constructor() { }

  public setSubject(keyValue: any, data: any) {
    const nextItem = {
      key: keyValue, value: data
    };
    this.changeSubject.next(nextItem);
  }

  public clearAllSubject() {
    this.changeSubject.next({ key: GlobalConstants.VIEW_PROJECT, value: null });
    this.changeSubject.next({ key: GlobalConstants.NAV_COLLPASED, value: null });
    this.changeSubject.next({ key: GlobalConstants.CRITERIA_ASSESSMENT, value: null });
    this.changeSubject.next({ key: GlobalConstants.SELECTED_ADMIN_CONFIG, value: null });
    localStorage.removeItem(GlobalConstants.SELECTED_ADMIN_CONFIG);
  }
}
