<footer class="footer">
    <div class="container-fluid">
        <div class="row py-3 text-muted">
            <div class="col-6 text-left">
                <ul class="list-inline mb-0">
                    <li class="list-inline-item mr-4">
                        v{{version}}
                    </li>
                    <!-- <li class="list-inline-item mr-3">
                        <a class="text-muted" href="#">Support</a>
                    </li>
                    <li class="list-inline-item mr-3">
                        <a class="text-muted" href="#">Help Center</a>
                    </li>
                    <li class="list-inline-item mr-3">
                        <a class="text-muted" href="#">Privacy</a>
                    </li> -->
                    <li class="list-inline-item">
                        <a class="text-muted"
                            href="https://stage-static.crsrescore.org/rescore-user-terms-and-conditions.pdf"
                            target="_blank">Terms of Service</a>
                    </li>
                </ul>
            </div>
            <div class="col-6 text-right">
                <div class="d-flex">
                    <div class="align-self-center ml-auto">
                        &copy; 2023 <a href="https://councilforresponsiblesport.org/" class="text-muted">Council for
                            Responsible Sport.</a>
                    </div>
                    <div class="align-self-center mr-2">&nbsp;Powered by</div>
                    <a href="https://www.tcs.com/" class="tcs-logo" target="_blank">
                        <img src="assets/images/tcs-logo-xs.png" alt="TCS Logo" height="18" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>
