import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SwitcherComponent } from './core/components/switcher/switcher.component';
import { LayoutAppComponent } from './core/_components/layout-app/layout-app.component';
import { AuthGuard } from './core/_guards/auth.guard';

const loginModule = () => import('./modules/login/login.module').then(x => x.LoginModule);
const onboardingModule = () => import('./modules/onboarding/onboarding.module').then(x => x.OnboardingModule);
const userDashboardModule = () => import('./modules/user-dashboard/user-dashboard.module').then(x => x.UserDashboardModule);

const routes: Routes = [
    { path: '', redirectTo: '/', pathMatch: 'full' },
    { path: '', loadChildren: loginModule },
    { path: '', loadChildren: onboardingModule, canActivate: [AuthGuard] },
    { path: '', loadChildren: userDashboardModule, canActivateChild: [AuthGuard] },
    {
        path: 'certifications',
        component: LayoutAppComponent,
        loadChildren: () => import('./modules/projects/projects.module').then(x => x.ProjectsModule),
        canActivateChild: [AuthGuard]
    },
    { path: '""', component: SwitcherComponent, canActivate: [AuthGuard] },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(routes, { enableTracing: false })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
