<div class="sidebar-container">
    <div class="logo-wrapper">
        <a routerLink="/">
            <img src="assets/images/sitelogo-compact-sm-w.png" alt="ReScore" class="logo-compact" />
            <img src="assets/images/sitelogo-sm-w.png" alt="ReScore" class="logo" />
        </a>
    </div>
    <div class="scroll-container">
        <ng-scrollbar [autoHeightDisabled]="false">
            <nav class="navbar top-nav navbar-dark bg-inverse">
                <ul class="nav navbar-nav w-100">
                    <!--Certifications-->
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/certifications']" routerLinkActive="active">
                            <fa-icon icon="tasks" class="nav-item-icon" [fixedWidth]="true"></fa-icon>
                            <span class="nav-item-text align-middle">Certifications</span>
                        </a>
                    </li>
                    <!--Case Studies-->
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/case-studies']" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">
                            <fa-icon icon="file-alt" class="nav-item-icon" [fixedWidth]="true"></fa-icon>
                            <span class="nav-item-text">Case Studies</span>
                        </a>
                    </li>
                    <!--Catalogues-->
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/catalogues']" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact:
                        true}">
                            <fa-icon icon="newspaper" class="nav-item-icon" [fixedWidth]="true"></fa-icon>
                            <span class="nav-item-text">Catalogues</span>
                        </a>
                    </li>
                    <ng-container *ngIf="isAdmin">
                        <!--Admin Settings-->
                        <li class="nav-item nav-section-title">
                            <span class="nav-item-text">Admin Settings</span>
                        </li>
                        <!--Certification Config -->
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/admin/certification-config/event-criterions']"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                                <fa-icon icon="clipboard-list" class="nav-item-icon" [fixedWidth]="true"></fa-icon>
                                <span class="nav-item-text">Certification Config</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <!--Catalogue Settings-->
                            <a class="nav-link" [routerLink]="['/admin/catalogue-settings']" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact:true}">
                                <fa-icon icon="sticky-note" class="nav-item-icon" [fixedWidth]="true"></fa-icon>
                                <span class="nav-item-text">Catalogue Settings</span>
                            </a>
                        </li>
                        <li class="nav-item" *ngIf="!isRoleProgramAdmin">
                            <!--User Management-->
                            <a class="nav-link" [routerLink]="['/admin/user-management']" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">
                                <fa-icon icon="users-cog" class="nav-item-icon" [fixedWidth]="true"></fa-icon>
                                <span class="nav-item-text">User Management</span>
                            </a>
                        </li>
                        <!--Manage Access Codes-->
                        <li class="nav-item" *ngIf="!isRoleProgramAdmin">
                            <a class="nav-link" [routerLink]="['/admin/manage-access-codes']" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">
                                <fa-icon icon="ticket-alt" class="nav-item-icon" [fixedWidth]="true"></fa-icon>
                                <span class="nav-item-text">Manage Access Codes</span>
                            </a>
                        </li>
                        <!--Application Settings-->
                        <li class="nav-item">
                            <a class="nav-link"
                                [routerLink]="['/admin/applicaiton-settings/payment-integration-settings']"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <fa-icon icon="cogs" class="nav-item-icon" [fixedWidth]="true"></fa-icon>
                                <span class="nav-item-text">Application Settings</span>
                            </a>
                        </li>
                    </ng-container>
                    <li class="nav-item nav-section-title">
                        <span class="nav-item-text">Quick Links</span>
                    </li>
                    <!--GuideBook-->
                    <!-- <li [ngClass]="isCollapsed ? 'nav-item nav-item-collapsed' : 'nav-item nav-item-expanded'"
                        routerLinkActive="active">
                        <a class="nav-link">
                            <fa-icon [icon]="faInfoCircle" class="nav-item-icon" [fixedWidth]="true"></fa-icon>
                            <span class="nav-item-text">GuideBook</span>
                        </a>
                    </li> -->
                    <!--Forum/FAQ's-->
                    <!-- <li [ngClass]="isCollapsed ? 'nav-item nav-item-collapsed' : 'nav-item nav-item-expanded'"
                        routerLinkActive="active">
                        <a class="nav-link">
                            <fa-icon [icon]="faComments" class="nav-item-icon" [fixedWidth]="true"></fa-icon>
                            <span class="nav-item-text">Forum/FAQ's</span>
                        </a>
                    </li> -->
                    <!--Help Center-->
                    <li class="nav-item">
                        <a class="nav-link">
                            <fa-icon icon="life-ring" class="nav-item-icon" [fixedWidth]="true"></fa-icon>
                            <span class="nav-item-text">Help Center</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </ng-scrollbar>
    </div>
</div>
