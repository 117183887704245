import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-footer1',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent1 implements OnInit {

    // Initialize Variables
    version = environment.version;

    constructor() { }

    ngOnInit(): void {
    }

}
