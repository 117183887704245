import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class UserManagementService {

    constructor(private httpClient: HttpClient, private authService: AuthenticationService) { }

    public getUsersByRole(role: string, page: number, size: number, searchText: string, sortColumnName: string,
        sortOrder: string): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.authService.getStoredAccessToken()}`);

        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('size', size.toString());
        params = params.append('searchableText', searchText.toString());
        params = params.append('sortColumnName', sortColumnName.toString());
        params = params.append('sortOrder', sortOrder.toString());

        return this.httpClient.get(`${environment.apiUrl}/users/role/${role}`, { headers, params });
    }

    public getUser(userId: number): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.authService.getStoredAccessToken()}`);

        return this.httpClient.get(`${environment.apiUrl}/users/${userId}`, { headers });
    }

    public editUserStatus(uId: number, status: string): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.authService.getStoredAccessToken()}`);

        const body = JSON.stringify({
            userId: uId,
            activationStatus: status
        });

        return this.httpClient.put(`${environment.apiUrl}/users/activationStatus`, body, { headers });
    }

    public getAllRoles(): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.authService.getStoredAccessToken()}`);

        return this.httpClient.get(`${environment.apiUrl}/user/getAllRoles`, { headers });
    }

    public inviteUser(data: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.authService.getStoredAccessToken()}`);

        const body = JSON.stringify(data);

        return this.httpClient.post(`${environment.apiUrl}/user/inviteUser`, body, { headers });
    }

    public addTransaction(data: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.authService.getStoredAccessToken()}`);

        const body = JSON.stringify(data);

        return this.httpClient.post(`${environment.apiUrl}/users/coupon/apply`, body, { headers });
    }

    public getAllTransaction(userId: number): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.authService.getStoredAccessToken()}`);

        return this.httpClient.get(`${environment.apiUrl}/pay/${userId}`, { headers });
    }

    public addAccessCode(data: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.authService.getStoredAccessToken()}`);

        const body = JSON.stringify(data);

        return this.httpClient.post(`${environment.apiUrl}/users/coupon`, body, { headers });
    }

    public getAccessCodeList(page: number, size: number, searchText: string, sortColumnName: string, sortOrder: string): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.authService.getStoredAccessToken()}`);

        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('size', size.toString());
        params = params.append('searchableText', searchText.toString());
        params = params.append('sortColumnName', sortColumnName.toString());
        params = params.append('sortOrder', sortOrder.toString());

        return this.httpClient.get(`${environment.apiUrl}/users/coupon`, { headers, params });
    }

    public getUserAccessCodeList(coupon: string): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.authService.getStoredAccessToken()}`);

        let params = new HttpParams();
        params = params.append('coupon', coupon.toString());

        return this.httpClient.get(`${environment.apiUrl}/users/coupon/manage`, { headers, params });
    }

    public deleteAccessCode(coupon: string): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.authService.getStoredAccessToken()}`);

        return this.httpClient.delete(`${environment.apiUrl}/users/coupon/${coupon}`, { headers });
    }

    public deleteUserAccessCode(coupon: string, userId: number): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.authService.getStoredAccessToken()}`);

        let params = new HttpParams();
        params = params.append('coupon', coupon.toString());
        params = params.append('userId', userId.toString());

        return this.httpClient.delete(`${environment.apiUrl}/users/coupon/manage`, { headers, params });
    }

}
