import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { map, mapTo, share, tap } from 'rxjs/operators';
import { SpinnerService } from 'src/app/core/_services/spinner.service';
import { GlobalConstants } from 'src/app/shared/_constants/global-constants';
import { Tokens } from 'src/app/shared/_models/tokens';
import { environment } from '../../../environments/environment';
import { LoginUser, SignupUser } from '../../shared/_models/user';
import { encrytString } from '../_helpers/rsa-util';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public userSubject = new Subject<{ key: string; value: any }>();
    public onUserSubjectChanges = this.userSubject.asObservable().pipe(share());

    constructor(private httpClient: HttpClient, private router: Router, private spinnerService: SpinnerService) { }

    public login(loginData: LoginUser): Observable<any> {
        // this api uses to sign in the user with user's credentials if success else throws an exception
        loginData.password = encrytString(loginData.password);

        const OAUTH_CLIENT = 'client1';
        const OAUTH_SECRET = encrytString('secret1');
        const HTTP_OPTIONS = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Basic ' + btoa(OAUTH_CLIENT + ':' + btoa(OAUTH_SECRET))
            })
        };

        const body = new HttpParams()
            .set('grant_type', 'password')
            .set('username', loginData.emailId)
            .set('password', btoa(loginData.password));

        const url = environment.apiUrl.split('/v1');
        return this.httpClient.post(`${url[0]}/oauth/token`, body, HTTP_OPTIONS)
            .pipe(map((tokens: any) => {
                // Store tokens in session storage
                if (tokens) {
                    this.setTokens(tokens);
                }
            }));

    }

    public signup(user: SignupUser): Observable<any> {
        // this api uses to save user's data into DB with encrypted password
        user.password = btoa(encrytString(user.password));
        const body = JSON.stringify(user);
        const HTTP_OPTIONS = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.httpClient.post(`${environment.apiUrl}/user/signUp`, body, HTTP_OPTIONS);
    }

    public logout() {
        // this api uses to clear all tokens
        const accessToken = this.getStoredAccessToken();
        const HTTP_OPTIONS = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            })
        };
        const body = JSON.stringify({
            access_token: accessToken,
            refresh_token: this.getStoredRefreshToken()
        });

        return this.httpClient.post(`${environment.apiUrl}/user/logout`, body, HTTP_OPTIONS)
            .pipe(map((tokens: any) => {
                // Clear tokens in session storage
                this.removeTokens();
                this.router.navigate(['/']);
            }));
    }

    public getRefreshToken(refreshToken: any): Observable<any> {
        // this api uses to refreshed the expired token and provides new token and set to localstotage

        const OAUTH_CLIENT = 'client1';
        const OAUTH_SECRET = encrytString('secret1');
        const HTTP_OPTIONS = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Basic ' + btoa(OAUTH_CLIENT + ':' + btoa(OAUTH_SECRET))
            })
        };

        const body = new HttpParams()
            .set('grant_type', 'refresh_token')
            .set('refresh_token', String(this.getStoredRefreshToken()));

        const url = environment.apiUrl.split('/v1');
        return this.httpClient.post(`${url[0]}/oauth/token`, body, HTTP_OPTIONS)
            .pipe(map((tokens: any) => {
                // Store access token after refreshed in session storage
                if (tokens) {
                    this.setTokens(tokens);
                }
            }));
    }


    // Token methods
    public getStoredAccessToken() {
        return localStorage.getItem(GlobalConstants.ACCESS_TOKEN);
    }

    public getStoredRefreshToken() {
        return localStorage.getItem(GlobalConstants.REFRESH_TOKEN);
    }

    public setAccessToken(accessToken: string) {
        localStorage.setItem(GlobalConstants.ACCESS_TOKEN, accessToken);
    }

    public setTokens(tokens: any) {
        localStorage.setItem(GlobalConstants.ACCESS_TOKEN, tokens.access_token);
        localStorage.setItem(GlobalConstants.REFRESH_TOKEN, tokens.refresh_token);
    }

    public removeTokens() {
        localStorage.removeItem(GlobalConstants.ACCESS_TOKEN);
        localStorage.removeItem(GlobalConstants.REFRESH_TOKEN);
        localStorage.clear();
        this.userSubject.next({ key: GlobalConstants.USER_DETAILS, value: null });
    }

    public storeUserDetailsSubject(user: any) {
        this.userSubject.next({ key: GlobalConstants.USER_DETAILS, value: user });
    }

    public getLocalStoredUserDetails() {
        return JSON.parse(String(localStorage.getItem(GlobalConstants.USER_DETAILS)));
    }

    public clearStorageOnRefreshExpires(err: HttpErrorResponse) {
        // If refresh token expires clear all cookies then land to main page
        if ((err.error.error === 'invalid_token' || err.error.error === 'invalid_grant')
            && err.error.error_description.includes('Invalid refresh token')) {
            this.removeTokens();
            alert('Session expired, please login again!');
            this.router.navigate(['/']);
        }
    }
}
