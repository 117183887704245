import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { RoleEnum } from 'src/app/modules/_enums/role.enum';
import { AuthenticationService } from 'src/app/modules/_services/authentication.service';
import { User } from 'src/app/shared/_models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private authService: AuthenticationService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.authService.getStoredAccessToken()) {
      return true;

    } else {
      this.navigateToLogin();
      return false;
    }
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const user: User = this.authService.getLocalStoredUserDetails();

    if (user) {
      if (user.isPaid === 'N') {
        // If user tries to jump on project dashborad when payment-mode - N
        this.router.navigate(['/onboarding']);
        return false;

      } else if (user.isPaid === 'Y' && (state.url.includes('/onboarding'))) {
        // If user tries to jump on project dashborad when payment-mode - N
        this.router.navigate(['/certifications']);
        return false;

      } else if (user.role !== RoleEnum.systemAdmin && (state.url === '/admin/user-management')) {
        // If user != Admin is tried to jump on admin-panel
        this.router.navigate(['/certifications']);
        return false;

      } else if ((user.role !== RoleEnum.systemAdmin) && (user.role !== RoleEnum.programAdmin) &&
        (state.url === '/admin/catalogue-settings' || state.url.includes('/admin/application-settings') ||
          state.url.includes('/admin/certification-config/') || state.url === '/admin/manage-access-codes')) {
        // If user != Admin && user != Program Admin is tried to jump on project-dashboard
        this.router.navigate(['/certifications']);
        return false;
      } else {
        return true;
      }

    } else {
      this.navigateToLogin();
      return false;
    }
  }


  navigateToLogin() {
    this.authService.removeTokens();
    this.router.navigate(['/']);
  }
}
