import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.error instanceof ErrorEvent) {
                    // Do nothing and pass error as is
                } else {
                    if (error.status === 0) {
                        return throwError(new HttpErrorResponse({
                            error: {
                                status: 0,
                                message: `An error occurred. Try again in sometime. If the issue persists, kindly reach out to Support.`
                            }
                        }));
                    } else {
                        return throwError(error);
                    }
                    // else if (error.status === 500) {
                    //     return throwError(new HttpErrorResponse({
                    //         error: {
                    //             status: error.status,
                    //             message: `An error occurred. Try again in sometime. If the issue persists, kindly reach out to Support.`
                    //         }
                    //     }));
                    // }
                }
                return throwError(error);
            })
        );
    }
}
