<div class="app-wrapper" [ngClass]="{'sidebar-sm': sidebarSizeSm}">
    <app-sidebar class="sidebar"></app-sidebar>
    <div class="main">
        <app-header1></app-header1>
        <main class="content">
            <router-outlet></router-outlet>
        </main>
        <app-footer1></app-footer1>
    </div>
</div>
