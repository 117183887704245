import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from 'src/app/modules/_services/authentication.service';

import { GlobalConstants } from 'src/app/shared/_constants/global-constants';
import { User } from 'src/app/shared/_models/user';
import { RoleEnum } from 'src/app/modules/_enums/role.enum';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    user: User;
    isAdmin = false;
    isRoleProgramAdmin = false;

    constructor(
        private authService: AuthenticationService
    ) { }


    ngOnInit(): void {
        this.user = this.authService.getLocalStoredUserDetails();
        this.authService.storeUserDetailsSubject(this.user);

        if (this.user) {
            if (
                this.user.role === RoleEnum.systemAdmin ||
                this.user.role === RoleEnum.programAdmin
            ) {
                this.isAdmin = true;
            }
            if (this.user.role === RoleEnum.programAdmin) {
                this.isRoleProgramAdmin = true;
            }
        } else { this.isAdmin = false; }

    }

}
