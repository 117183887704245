import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    private sidebarSizeSm$: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);

    constructor() { }

    getPasswordValidationPattern(): string {
        return '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*])[A-Za-z\d#?!@$%^&*].{7,}$';
    }

    isSidebarSizeSmall(): Observable<boolean> {
        if (localStorage.getItem('upref_sb_sm_size') === 'true') {
            this.sidebarSizeSm$.next(true);
        }
        return this.sidebarSizeSm$;
    }

    sidebarToggleSize(): void {
        this.sidebarSizeSm$.next(!this.sidebarSizeSm$.getValue());
        localStorage.setItem('upref_sb_sm_size', this.sidebarSizeSm$.getValue());
    }

    getInitials(name: string): string {
        return name.split(' ').map(i => i.charAt(0)).join('').toUpperCase();
    }
}
