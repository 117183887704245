import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { encrytString } from '../_helpers/rsa-util';
import { AuthenticationService } from './authentication.service';


@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private httpClient: HttpClient, private authService: AuthenticationService) { }

    public verifyEmail(code: string): Observable<any> {
        // Verify user by cliking the verification link sent to user after signup
        let params = new HttpParams();
        params = params.append('code', code);
        return this.httpClient.get(`${environment.apiUrl}/user/verify`, { params });
    }

    public changePasswordVerification(code: string): Observable<any> {
        let params = new HttpParams();
        params = params.append('code', code);
        return this.httpClient.get(`${environment.apiUrl}/user/tokenVerification`, { params });
    }

    public getUserDetails(): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.authService.getStoredAccessToken()}`);

        return this.httpClient.get(`${environment.apiUrl}/user/getUserDetails`, { headers });
    }

    public getPaymentDetails(userId: number): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.authService.getStoredAccessToken()}`);

        return this.httpClient.get(`${environment.apiUrl}/pay`, { headers });
    }

    public forgotPassword(email: string): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');

        const body = JSON.stringify({
            emailId: email
        });

        return this.httpClient.post(`${environment.apiUrl}/user/forgotPassword`, body, { headers });
    }

    public resetPassword(user: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');

        user.password = btoa(encrytString(user.password));
        const body = JSON.stringify(user);

        return this.httpClient.post(`${environment.apiUrl}/user/resetPassword`, body, { headers });
    }

    public resendVerificationMail(user: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');

        const body = JSON.stringify(user);

        return this.httpClient.post(`${environment.apiUrl}/user/resendVerificationMail`, body, { headers });
    }

    public updateUserProfile(userProfile: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.authService.getStoredAccessToken()}`);

        const body = JSON.stringify(userProfile);

        return this.httpClient.post(`${environment.apiUrl}/user/saveUserProfile`, body, { headers });
    }

    public uploadProfilePic(profilePic: File): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', `Bearer ${this.authService.getStoredAccessToken()}`);

        const formData: FormData = new FormData();
        formData.append('profileImage', profilePic);

        return this.httpClient.put(`${environment.apiUrl}/user/profile/image/upload`, formData, { headers });
    }
}
