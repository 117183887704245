<nav class="header navbar navbar-expand-lg fixed-top shadow-sm">
    <a class="sidebar-toggle" (click)="toggleSidebar()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" height="32" width="32">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M20 8h-9m9 4H4m0 0 3-3m-3 3 3 3m13 1h-9" id="mainIconPathAttribute"></path>
        </svg>
    </a>

    <div class="ml-auto">
        <ul class="navbar-nav navbar-align ml-auto">
            <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-right">
                <div class="d-flex justify-content-between align-items-center" id="accountInfoDropdown"
                    ngbDropdownToggle>
                    <div class="left-border pl-3">
                        <img class="rounded-circle" alt="" [ngClass]="{'rounded-border' : userAvatar}"
                            [src]="userAvatar ? userAvatar : './assets/images/default-avatar-sm.PNG'"
                            data-holder-rendered="true" width='42px' height="40px">
                    </div>
                </div>
                <div ngbDropdownMenu aria-labelledby="accountInfoDropdown">
                    <span class="font-weight-bold text-capitalize name-label">{{ userFullName | lowercase}}</span>
                    <button ngbDropdownItem (click)="onAccountSettings()">Account
                        Settings</button>
                    <button ngbDropdownItem (click)="onLogout()">Log out</button>
                </div>
            </li>
        </ul>
    </div>
</nav>
