<div class="loader" [ngClass]="[loaderWrapperClass]">
    <div class="loader-icon d-flex align-items-center" [ngClass]="[loaderClass]">
        <span class="spinner-grow" role="status" aria-hidden="true"
            [ngClass]="[loadingIconClass, loadingIconSize]"></span>
        <span class="spinner-grow ml-1" role="status" aria-hidden="true" style="animation-delay: 200ms;"
            [ngClass]="[loadingIconClass, loadingIconSize]"></span>
        <span class="spinner-grow ml-1" role="status" aria-hidden="true" style="animation-delay: 400ms;"
            [ngClass]="[loadingIconClass, loadingIconSize]"></span>
        <span *ngIf="showMessage" class="ml-2" [ngClass]="[loadingMessageClass]">{{loadingMessage}}</span>
    </div>
</div>
