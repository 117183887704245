import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/modules/_services/authentication.service';
import { SubjectService } from 'src/app/modules/_services/subject.service';
import { GlobalConstants } from 'src/app/shared/_constants/global-constants';
import { DataService } from 'src/app/shared/_services/data.service';
import { UtilityService } from 'src/app/shared/_services/utility.service';
import { SpinnerService } from '../../_services/spinner.service';

@Component({
    selector: 'app-header1',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent1 implements OnInit {

    // Initialize Variables
    userFullName = '';
    userAvatar = undefined;
    isAccountSettingShown = false;
    subscription: Subscription;
    sidebarToggleSize = false;

    constructor(
        private router: Router,
        private utilityService: UtilityService,
        private spinnerService: SpinnerService,
        private authService: AuthenticationService,
        private subjectService: SubjectService
    ) { }

    ngOnInit(): void {
        this.getUserDetails();
    }

    toggleSidebar(): void {
        this.utilityService.sidebarToggleSize();
    }

    getUserDetails() {
        this.subscription = this.authService.onUserSubjectChanges.subscribe(element => {
            if (element.key === GlobalConstants.USER_DETAILS) {
                if (element.value) {
                    this.userFullName = element.value.firstName + ' ' + element.value.lastName;
                    this.userAvatar = element.value.profilePhotoLink;
                }

            } else {
                const user = this.authService.getLocalStoredUserDetails();
                if (user) {
                    this.userFullName = user.firstName + ' ' + user.lastName;
                    this.userAvatar = user.profilePhotoLink;
                }
            }
        });
    }

    onLogout() {
        this.spinnerService.spinnerStarted();
        this.authService.logout()
            .pipe(first())
            .subscribe({
                next: () => {
                    this.spinnerService.spinnerEnded();
                    this.router.navigate(['/']);
                },
                error: e => {
                    this.spinnerService.spinnerEnded();
                    localStorage.clear();
                    this.subjectService.clearAllSubject();
                    this.router.navigate(['/']);
                }
            });
    }

    onAccountSettings() {
        // Open account settings page
        this.router.navigate(['account-settings']);
    }
}
