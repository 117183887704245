import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './components/header/header.component';
import { HeaderComponent1 } from './_components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { FooterComponent1 } from './_components/footer/footer.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SwitcherComponent } from './components/switcher/switcher.component';
import { LayoutAppComponent } from '../core/_components/layout-app/layout-app.component';
import { SidebarComponent } from '../core/_components/sidebar/sidebar.component';

@NgModule({
    declarations: [
        HeaderComponent,
        HeaderComponent1,
        FooterComponent,
        FooterComponent1,
        SpinnerComponent,
        SwitcherComponent,
        LayoutAppComponent,
        SidebarComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        SpinnerComponent,
        SwitcherComponent,
        LayoutAppComponent
    ],
    providers: []
})
export class CoreModule { }
