export class GlobalConstants {

    // Signup user-name
    public static SIGNUP_USERNAME = 'SIGNUP_USERNAME';

    // Reset password key
    public static EMAIL_KEY = 'EMAIL_KEY';

    // Tokens
    public static ACCESS_TOKEN = 'ACCESS_TOKEN';
    public static REFRESH_TOKEN = 'REFRESH_TOKEN';

    // Onboarding
    public static EMAIL_VERIFICATION = 'EMAIL_VERIFICATION';
    public static UPDATE_PROFILE = 'UPDATE_PROFILE';
    public static PAYMENT_SUMMARY = 'PAYMENT_SUMMARY';
    public static PAYMENT_STATUS = 'PAYMENT_STATUS';

    // User/Payment Object
    public static USER_DETAILS = 'USER_DETAILS';
    public static PAYMENT_DETAILS = 'PAYMENT_DETAILS';

    // Paypal page
    public static PAYPAL_ORDER_DETAILS = 'PAYPAL_ORDER_DETAILS';
    public static ACCESS_TOKEN_EXPIRED = 'ACCESS_TOKEN_EXPIRED';

    // Sorting
    public static SORT_ORDER_DESC = 'DESC';
    public static SORT_ORDER_ASC = 'ASC';

    public static STANDARD_TYPE = 'STANDARD_TYPE';
    public static ONBOARDING_STEP = 'ONBOARDING_STEP';
    public static VIEW_PROJECT = 'VIEW_PROJECT';
    public static NAV_COLLPASED = 'NAV_COLLPASED';
    public static CRITERIA_ASSESSMENT = 'CRITERIA_ASSESSMENT';
    public static NAV_PROJECT = 'NAV_PROJECT';
    public static NAV_ADMIN = 'NAV_ADMIN';
    public static NAV_ADMIN_SETTING = 'NAV_ADMIN_SETTING';
    public static SELECTED_ADMIN_CONFIG = 'SELECTED_ADMIN_CONFIG';

    // support emails
    public static SUPPORT_EMAIL = 'support@sportrescore.org';
}
