import { Component, Input, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

    // Initialize component interaction variables
    @Input() loaderClass = '';
    @Input() loaderWrapperClass = '';
    @Input() showMessage = true;
    @Input() loadingMessage = 'Loading...';
    @Input() loadingMessageClass = '';
    @Input() loadingIconClass = '';
    @Input() loadingIconSize = 'fa-2x';

    // Initialize Fontawesome
    faSpinner = faSpinner;

    constructor() { }

    ngOnInit(): void { }

}
