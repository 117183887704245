import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/modules/_services/authentication.service';
import { UserService } from 'src/app/modules/_services/user.service';
import { GlobalConstants } from 'src/app/shared/_constants/global-constants';
import { User } from 'src/app/shared/_models/user';

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss']
})
export class SwitcherComponent implements OnInit {
  toastContainer: ToastContainerDirective;

  constructor(private userService: UserService, private router: Router, private authService: AuthenticationService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.toastr.overlayContainer = this.toastContainer;
    // Call get user api for onboarding process
    this.getUserDetails();
  }

  getUserDetails() {
    this.userService.getUserDetails().subscribe(response => {
      if (response) {

        const paymentDetails = {
          paymentAmount: response.paymentAmount,
          paymentCurrency: response.paymentCurrency,
          paypalClientId: response.paypalClientId
        };

        // Need to stringyfy 'USER/PAYMENT' when get the user.
        localStorage.setItem(GlobalConstants.USER_DETAILS, JSON.stringify(response.userDetails));
        localStorage.setItem(GlobalConstants.PAYMENT_DETAILS, JSON.stringify(paymentDetails));

        this.proceedToNextRoute(response.userDetails);
      }

    }, (e: HttpErrorResponse) => {
      if (e.error) {
        // If refreshed token is also get expired then route to login page
        if (e.error.error === 'invalid_token' || e.error.error === 'invalid_grant') {
          this.authService.clearStorageOnRefreshExpires(e);
        } else {
          this.toastr.error(e.error.error_description, 'ReScore');
        }
      }
    });
  }

  proceedToNextRoute(user: User) {
    if (user.isVerified === 'Y') {
      if (user.isProfileCompleted === 'Y') {
        if (user.isPaid === 'Y') {
          this.router.navigate(['/certifications']);

        } else if (user.isPaid === 'N') {
          this.router.navigate(['/onboarding']);
        }

      } else if (user.isProfileCompleted === 'N') {
        this.router.navigate(['/onboarding']);
      }

    } else if (user.isVerified === 'N') {
      this.router.navigate(['/onboarding']);
    }
  }

}
